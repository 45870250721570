import { AfterViewInit, Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
    selector: 'app-tess-product',
    templateUrl: './tess-product.component.html',
    styleUrls: ['./tess-product.component.scss']
})
export class TessProductComponent implements OnInit, AfterViewInit {

    constructor() { }

    ngOnInit() { }

    ngAfterViewInit(): void {
        TessProductComponent.initOwlCarousel();
    }

    private static initOwlCarousel(): void {
        $('.testimonial-style-two').owlCarousel({
            loop: true,
            margin: 10,
            nav: true,
            smartSpeed: 500,
            autoplay: true,
            navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 1
                },
                800: {
                    items: 2
                },
                1024: {
                    items: 2
                },
                1200: {
                    items: 3
                }
            }
        });
    }

}
