import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { DocumentationDataService } from '../../../documentation/services/documentation-data.service';
import { NavbarCommunicationService } from '../../../../services/navbar-communication.service';
import { DocumentationPage } from '../../../documentation/models/documentation-page';
import { WidgetTypes } from '../../../documentation/models/widgets/widget-types';
import { BaseWidget } from '../../../documentation/models/widgets/base-widget';
import { SelfUnsubscribe } from '../../../../utilities/self-unsubscribe';
import { DocumentationCommunicationService } from '../../../documentation/services/documentation-communication.service';

class ResultData {
    title: string;
    link: Array<string>;
    entries = new Array<ResultEntry>();
}

class ResultEntry {
    closestHeadline: string;
    fragment: string;
    content: string;
}

export type SearchStateType = 'show' | 'close' | 'cancel';

@Component({
    selector: 'app-documentation-search',
    templateUrl: './documentation-search.component.html',
    styleUrls: ['./documentation-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentationSearchComponent extends SelfUnsubscribe implements OnInit, AfterViewInit {

    @Output() showSearch = new EventEmitter<SearchStateType>();
    @Output() resultsChange = new EventEmitter<void>();
    @ViewChild('search_input', {static: false}) input: ElementRef;
    @ViewChild('scroll_anchor', {static: false}) anchor: ElementRef<HTMLElement>;
    @ViewChild('scroll_container', {static: false}) scrollContainer: ElementRef<HTMLElement>;

    activeSearch: boolean = false;
    paginatedResultData = new Array<ResultData>();
    totalFilteredData = new Array<ResultData>();
    loadedDataIndex = 0;

    private observer: IntersectionObserver;

    constructor(private dataService: DocumentationDataService,
                private navbarService: NavbarCommunicationService,
                private documentationCommunicationService: DocumentationCommunicationService,
                private router: Router,
                private cdRef: ChangeDetectorRef,
                @Inject(DOCUMENT) private document: any) { super(); }

    ngOnInit() { }

    ngAfterViewInit(): void {
        this.listenToSearch();
        this.listenToInfiniteScroll();
    }

    openSearch(): void {
        this.activeSearch = true;
        this.showSearch.emit('show');
        this.input.nativeElement.focus();
        if (window.innerWidth < 768) {
            this.document.body.style.overflow = 'hidden';
        }
    }

    closeSearch(action: SearchStateType): void {
        this.activeSearch = false;
        this.showSearch.emit(action);
        this.document.body.style.overflow = '';
        this.cdRef.detectChanges();
    }

    closeSearchOnMobile(event: Event): void {
        if (window.innerWidth < 768) {
            event.stopPropagation();
            this.closeSearch('cancel');
        }
    }

    navigateToPage(resultData: ResultData): void {
        this.router.navigate(['product', 'tess', 'documentation'].concat(resultData.link))
            .finally(() => this.navbarService.navigateFromSearch());
        this.closeSearch('close');
    }

    navigateToResult(result: ResultEntry, resultData: ResultData): void {
        if (!result.fragment) {
            this.navigateToPage(resultData);
            return;
        }

        this.router.navigate(['product', 'tess', 'documentation'].concat(resultData.link), {fragment: result.fragment})
            .finally(() => this.navbarService.navigateFromSearch());

        this.closeSearch('close');
    }

    clearSearchBox(): void {
        this.paginatedResultData = new Array<ResultData>();
        this.totalFilteredData = new Array<ResultData>();
        this.input.nativeElement.value = '';
    }

    private listenToInfiniteScroll(): void {
        this.observer = new IntersectionObserver((entries) => {
            this.loadMoreResults(entries);
        }, {root: this.scrollContainer.nativeElement, rootMargin: '150px'});

        this.observer.observe(<Element> (this.anchor.nativeElement));
    }

    private loadMoreResults(entries): void {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if ((<any> entry).isIntersecting && entry.target === this.anchor.nativeElement) {
                this.loadedDataIndex += 3;
                this.mapPaginationResults(this.loadedDataIndex);
            }
        });
    }

    private listenToSearch(): void {
        let subscription = fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                filter((value: any) => { // no results for under 2 characters typed
                    if (value.target.value.length <= 1) {
                        this.paginatedResultData = new Array<ResultData>();
                        this.totalFilteredData = new Array<ResultData>();
                        this.cdRef.detectChanges();
                        return false;
                    }
                    return true;
                }),
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => this.searchText())
            )
            .subscribe();
        this.addSubscription(subscription);
    }

    private searchText(): void {
        this.scrollContainer.nativeElement.scrollTop = 0;
        const toFind = this.input.nativeElement.value;
        this.paginatedResultData = new Array<ResultData>();
        this.totalFilteredData = new Array<ResultData>();
        this.mapSearchResults(toFind);
        this.mapPaginationResults(0);
        this.mapPaginationResults(3);
        this.loadedDataIndex = 3;
    }

    private mapPaginationResults(startIdx: number): void {
        const stopIdx = (startIdx + 3 > this.totalFilteredData.length) ? this.totalFilteredData.length : (startIdx + 3);

        for (let i = startIdx; i < stopIdx; i++) {
            this.paginatedResultData.push(this.totalFilteredData[i]);
        }

        if (this.totalFilteredData.length == 1 && this.paginatedResultData.length == 0) {
            this.paginatedResultData.push(this.totalFilteredData[0]);
        }
        this.resultsChange.emit();
    }

    private mapSearchResults(toFind): void {
        this.dataService.rootPage.pages.forEach(page => {
            this.recursivePagesIteration(page, toFind);
        });
    }

    private recursivePagesIteration(page: DocumentationPage, toFind: string): void {
        if (page.widgets) {
            this.findTextInPage(page, toFind);
        }
        page.pages.forEach(nestedPage => this.recursivePagesIteration(nestedPage, toFind));
    }

    private findTextInPage(page: DocumentationPage, toFind: string): void {
        let pageHasGoodWidget = false;
        const resultData = new ResultData();
        page.widgets
            .filter(w => !w.type.includes(WidgetTypes.IMAGE) && !w.type.includes(WidgetTypes.VIDEO))
            .forEach((widget: BaseWidget, index: number, widgetArray: BaseWidget[]): void => {
                if (widget.text.toLowerCase().includes(toFind.toLowerCase())) {
                    pageHasGoodWidget = true;
                    const result: ResultEntry = DocumentationSearchComponent.createResult(widget, widgetArray, index, toFind);
                    if (!result.closestHeadline) {
                        result.closestHeadline = page.leadHeadline;
                    }
                    resultData.entries.push(result);
                }
            });

        if (pageHasGoodWidget || page.title.toLowerCase().includes(toFind.toLowerCase())) {
            resultData.title = DocumentationSearchComponent.appendMarkToContent(page.title, toFind);
            resultData.link = page.link;
            this.totalFilteredData.push(resultData);
        }
    }

    private static createResult(widget: BaseWidget, widgetArray: BaseWidget[], index: number, toFind: string): ResultEntry {
        const result = new ResultEntry();

        if (widget.type === WidgetTypes.HEADLINE) {
            result.closestHeadline = this.appendMarkToContent(widget.text, toFind);
            result.fragment = widget.text.toLowerCase().split(' ').join('-');

            if (widgetArray[index + 1] && widgetArray[index + 1].type === WidgetTypes.TEXT) {
                result.content = widgetArray[index + 1].text;
            }

        } else if (widget.type === WidgetTypes.TEXT || widget.type === WidgetTypes.CARD) {
            result.content = this.appendMarkToContent(widget.text, toFind);
            this.anchorToClosestHeadline(index, result, widgetArray);
        }

        return result;
    }

    private static anchorToClosestHeadline(index: number, result: ResultEntry, widgetArray: BaseWidget[]): void {
        for (let i = index; i >= 0; i--) {  //find the nearest title above to anchor the 'non-headline' widget
            if (result.closestHeadline) break;

            if (widgetArray[i].type === WidgetTypes.HEADLINE) {
                result.closestHeadline = widgetArray[i].text;
                result.fragment = widgetArray[i].text.toLowerCase().split(' ').join('-');
            }
        }
    }

    private static appendMarkToContent(text: string, toFind: string): string {
        if (!toFind) {
            return '';
        }
        const startIdx = text.toLowerCase().indexOf(toFind.toLowerCase());
        const stopIdx = startIdx + toFind.length;

        if (startIdx == -1) {
            return text;
        }

        return text.substring(0, startIdx)
            + '<span class="matching-text">'
            + text.substring(startIdx, stopIdx)
            + '</span>'
            + this.appendMarkToContent(text.substring(stopIdx), toFind);
    }

    ngOnDestroy(): void {
        this.observer.disconnect();
    }
}
