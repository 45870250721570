import { AfterViewInit, Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
    selector: 'app-academy-product',
    templateUrl: './academy-product.component.html',
    styleUrls: ['./academy-product.component.scss']
})
export class AcademyProductComponent implements OnInit, AfterViewInit {

    constructor() { }

    ngOnInit() { }

    ngAfterViewInit(): void {
        this.initOwlCarousel();
    }

    private initOwlCarousel(): void {
        $('.sponsors-slider-one').owlCarousel({
            loop: true,
            margin: 10,
            nav: true,
            smartSpeed: 500,
            autoplay: true,
            navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                800: {
                    items: 3
                },
                1024: {
                    items: 4
                },
                1200: {
                    items: 4
                }
            }
        });
        $('.clients-carousel').owlCarousel({
            loop: true,
            margin: 30,
            nav: true,
            smartSpeed: 500,
            autoplay: true,
            navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 1
                },
                800: {
                    items: 1
                },
                1024: {
                    items: 2
                }
            }
        });
    }
}
