import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { DefaultToolbarComponent } from '../components/ng-squire/components/default-toolbar/default-toolbar.component';

@Injectable({
    providedIn: 'root'
})
export class DocumentationCommunicationService {

    public toolbar: DefaultToolbarComponent;
    public isPageDragging: boolean;
    public disablePageDrag: boolean;

    private anchorChangeSubject = new ReplaySubject<string[]>(1);
    private cardChangeSubject = new ReplaySubject<void>(1);

    public notifyAnchorChange(anchors: string[]): void {
        this.anchorChangeSubject.next(anchors);
    }

    public listenToAnchorChange(): Observable<string[]> {
        return this.anchorChangeSubject.asObservable();
    }

    public updateCard(): void {
        this.cardChangeSubject.next();
    }

    public cardChange(): Observable<void> {
        return this.cardChangeSubject.asObservable();
    }
}
