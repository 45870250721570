import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

export type FooterStyle = 'footer-style-one' | 'footer-style-two' | 'footer-style-three';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {

    @Input() footerStyle: FooterStyle;
    @Input() darkVersion: boolean = false;

    constructor() { }

    ngOnInit() { }

}
