import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { DocumentationSearchComponent } from './components/documentation-search/documentation-search.component';
import { LoadingComponent } from './components/loading/loading.component';


@NgModule({
    declarations: [
        FooterComponent,
        DocumentationSearchComponent,
        LoadingComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ],
    exports: [
        FooterComponent,
        DocumentationSearchComponent,
        LoadingComponent
    ]
})
export class SharedModule {}
