import { Subscription } from 'rxjs/';
import { OnDestroy } from '@angular/core';

export class SelfUnsubscribe implements OnDestroy {

    private subscriptions: Subscription[] = [];

    addSubscription(subscription: Subscription): void {
        this.subscriptions.push(subscription);
    }

    ngOnDestroy(): void {
        this.dispose();
    }

    dispose(): void {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }
}
