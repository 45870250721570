import { BaseWidget } from './widgets/base-widget';

export class DocumentationPage {
    id: string = 'page' + randomInteger();
    link: Array<string> = new Array<string>();
    title: string = 'Page Title';
    leadHeadline: string = 'Page Title';
    pages: Array<DocumentationPage>;
    widgets: Array<BaseWidget>;
}

function randomInteger() {
    return Math.floor(Math.random() * 1000000);
}
