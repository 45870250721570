import { AfterViewInit, Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

    constructor() { }

    ngOnInit() { }

    ngAfterViewInit(): void {
        HomeComponent.initOwlCarousel();
    }

    private static initOwlCarousel(): void {
        $('.single-item-carousel').owlCarousel({
            loop: true,
            margin: 0,
            items: 1,
            nav: true,
            smartSpeed: 700,
            autoplay: true,
            navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>']
        });
    }
}
