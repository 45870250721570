import { Injectable } from '@angular/core';
import { ConfigService } from './services/config.service';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class PreloadConfigResolver implements Resolve<Observable<any>> {

  public constructor(private configService: ConfigService) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.configService.loadConfig();
  }
}
