import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { ServiceDetailComponent } from './components/service-detail/service-detail.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TessProductComponent } from './components/tess-product/tess-product.component';
import { AcademyProductComponent } from './components/academy-product/academy-product.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './components/shared-module/shared.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ConfigService } from './services/config.service';
import { PreloadConfigResolver } from './preload-config.resolver';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

const easeInOutCubic = (t, b, c, d): number => (t /= d / 2) < 1 ? c / 2 * t * t + b : -c / 2 * ((--t) * (t - 2) - 1) + b;

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        AboutComponent,
        BlogDetailsComponent,
        BlogComponent,
        ContactComponent,
        PricingComponent,
        ServiceDetailComponent,
        NavbarComponent,
        TessProductComponent,
        AcademyProductComponent,
        ClickOutsideDirective
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        OverlayModule,
        NgxPageScrollCoreModule.forRoot({
            _logLevel: 0,
            easingLogic: easeInOutCubic,
            duration: 400
        })
    ],
    providers: [
        PreloadConfigResolver,
        ConfigService
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
