import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NavbarCommunicationService } from '../../services/navbar-communication.service';
import { debounce, throttle } from 'lodash-decorators';
import { Router } from '@angular/router';
import { DocumentationSearchComponent, SearchStateType } from '../shared-module/components/documentation-search/documentation-search.component';
import { SelfUnsubscribe } from '../../utilities/self-unsubscribe';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';

declare var $: any;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent extends SelfUnsubscribe implements OnInit, OnChanges {

    @Input() activeUrl: string;
    @Input() isScrollingDown: number;

    public phoneResolution: boolean = false;
    public activeProduct: string;
    public showProductDropdown: boolean = false;
    public expandElements = false;
    public showSecondNav: boolean;
    public expandMainNav: boolean;
    public activeDocumentationPage: string;
    public searchActive: SearchStateType;
    public showTessDropdown: boolean;
    public showAcademyDropdown: boolean;
    private navStateBeforeSearch: number;

    @ViewChild(DocumentationSearchComponent, {static: false}) searchComponent: DocumentationSearchComponent;

    @throttle(500)
    @HostListener('window:scroll')
    onWindowScroll() {
        this.expandElements = false;
        this.hideProductDropdown();
        this.closeMainNavbar();
    }

    @debounce(500)
    @HostListener('window:resize')
    onWindowResize() {
        this.phoneResolution = window.innerWidth < 768;
        this.hideProductDropdown();
        this.expandElements = false;
    };

    constructor(public navbarService: NavbarCommunicationService,
                private router: Router,
                private cdRef: ChangeDetectorRef,
                private pageScrollService: PageScrollService,
                @Inject(DOCUMENT) private document: any) { super(); }

    ngOnInit() {
        this.phoneResolution = window.innerWidth < 768;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.onUrlChange();
    }

    scrollToTop(): void {
        this.pageScrollService.scroll({
            document: this.document,
            scrollTarget: '#top'
        });
    }

    private onUrlChange(): void {
        this.showSecondNav = false;
        if (this.activeUrl.includes('/product/tess')) {
            this.showSecondNav = !this.phoneResolution;
            this.activeProduct = 'TESS';
        } else if (this.activeUrl.includes('/product/academy')) {
            this.showSecondNav = !this.phoneResolution;
            this.activeProduct = 'Academy';
        }
        if (this.activeUrl.includes('/documentation/')) {
            this.showSecondNav = true;
            this.activeDocumentationPage = this.activeUrl.split('/').pop().split('#')[0].split('-').join(' ');
            this.activeDocumentationPage = decodeURIComponent(this.activeDocumentationPage);
        } else {
            this.activeDocumentationPage = undefined;
        }
        setTimeout(() => this.cdRef.detectChanges(), 50);
    }

    toggleDocumentationSideMenu(event: Event): void {
        event.stopPropagation();
        this.navbarService.toggleDocumentationMenu(true);
    }

    toggleTessDropdown(): void {
        if (this.phoneResolution) {
            this.showTessDropdown = !this.showTessDropdown;
            this.showAcademyDropdown = false;
        } else {
            this.hideProductDropdown();
            this.router.navigate(['product', 'tess']);
        }
    }

    toggleAcademyDropdown(): void {
        if (this.phoneResolution) {
            this.showAcademyDropdown = !this.showAcademyDropdown;
            this.showTessDropdown = false;
        } else {
            this.hideProductDropdown();
            this.router.navigate(['product', 'academy']);
        }
    }

    toggleMainNav(): void {
        this.expandMainNav = !this.expandMainNav;
    }

    changeActiveSearch(value: SearchStateType): void {
        this.searchActive = value;
        if (this.phoneResolution && value === 'cancel') {
            setTimeout(() => this.navbarService.isScrollingDown = this.navStateBeforeSearch, 100);
        }
    }

    closeSearch(): void {
        this.searchComponent.closeSearch('cancel');
    }

    onSearchResultsShow(): void {
        this.cdRef.markForCheck();
    }

    /* functions below are only for resolution <= 768 (phone) */
    toggleNavbar(): void {
        if (this.phoneResolution) {
            this.expandElements = !this.expandElements;
        }
    }

    closeMainNavbar(): void {
        if (this.phoneResolution) {
            setTimeout(() => this.hideProductDropdown(), 300); /* delay for the animation */
            this.expandMainNav = false;
            this.showTessDropdown = false;
            this.showAcademyDropdown = false;
        }
    }

    closeSecondaryNavbar(event: Event): void {
        event.stopPropagation();
        if (this.phoneResolution) {
            this.expandElements = false;
        }
    }

    toggleShowProductDropdown(): void {
        this.showProductDropdown = !this.showProductDropdown;
    }

    hideProductDropdown(): void {
        this.showProductDropdown = false;
    }

    showDocumentationSearchOnMobile(): void {
        if (this.phoneResolution) {
            $('#search_bar').trigger('click');
            this.navStateBeforeSearch = this.navbarService.isScrollingDown;
            this.navbarService.isScrollingDown = 1;
            this.navbarService.isAnchorScrolling = true;
            setTimeout(() => this.navbarService.isAnchorScrolling = false, 50);
        }
    }
}
