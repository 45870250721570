import { BaseWidget } from './base-widget';
import { Text } from './text';
import { WidgetTypes } from './widget-types';

export class Card extends BaseWidget {
    readonly type: string = WidgetTypes.CARD;
    title: string = 'Card\'s Title Goes Here';
    html: string = '';
    theme: string = 'th-iris-blue';
    icon: string = 'info';
    textWidget = new Text();
}
