import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { debounce } from "lodash-decorators";
import { NavbarCommunicationService } from "./services/navbar-communication.service";
import { ViewportScroller } from "@angular/common";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public activeUrl: string = '';

    @HostListener('window:scroll')
    @debounce(10)
    onWindowScroll() {
        this.navbarService.onWindowScroll();
    }

    constructor(public navbarService: NavbarCommunicationService,
                private viewportScroller: ViewportScroller,
                private router: Router) { }

    ngOnInit(): void {
        this.listenToRouteEvents();
    }

    private listenToRouteEvents(): void {
        this.router.events
            .pipe(filter(e => e instanceof NavigationEnd))
            .subscribe((e: NavigationEnd) => {
                this.activeUrl = e.urlAfterRedirects;
                if (!e.urlAfterRedirects.split('#')[1]) { // if it's not anchor
                    window.scrollTo({top: 0});
                }
            });
    }
}
