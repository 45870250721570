import { Injectable } from '@angular/core';
import { DocumentationPage } from '../models/documentation-page';
import { Headline } from '../models/widgets/headline';
import { BaseWidget } from '../models/widgets/base-widget';
import { Text } from '../models/widgets/text';
import { Image } from '../models/widgets/image';
import r from 'random-words';
import { Card } from '../models/widgets/card';
import { Video } from '../models/widgets/video';
import { WidgetTypes } from '../models/widgets/widget-types';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DocumentationSaveResponse } from '../models/http/documentation-save-response';
import { DocumentationSaveRequest } from '../models/http/documentation-save-request';
import { DocumentationGetResponse } from '../models/http/documentation-get-response';
import cardIcons from '../../../../assets/cardIcons.json';
import { ImageUploadResponse } from '../models/http/image-upload-response';
import { ConfigService } from '../../../services/config.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentationDataService {
  public rootPage: DocumentationPage;

  private readonly serverApi: string;

  constructor(private http: HttpClient, private config: ConfigService) {
    this.rootPage = new DocumentationPage();
    this.rootPage.id = 'ROOT_PAGE';
    this.serverApi = environment.production
      ? this.config.get('api')
      : this.config.get('api-dev');
  }

  public getDocumentationData(product: string): Observable<void> {
    const params = {
      productName: product,
    };
    return this.http
      .get<DocumentationGetResponse>(`${this.serverApi}/DocumentationGet`, {
        params,
      })
      .pipe(
        map((response) => {
          this.rootPage.pages = JSON.parse(response.Data);
          if (!this.rootPage.pages || !this.rootPage.pages.length) {
            this.createHomePage();
          }
        }),
        catchError((e) => throwError(e))
      );
  }

  public saveDocumentationData(
    product: string
  ): Observable<DocumentationSaveResponse> {
    const data: DocumentationSaveRequest = {
      Data: JSON.stringify(this.rootPage.pages),
    };
    const params = {
      productName: product,
    };
    return this.http.post<DocumentationSaveResponse>(
      `${this.serverApi}/DocumentationInsert`,
      data,
      { params }
    );
  }

  public uploadImage(
    file: File,
    product: string
  ): Observable<ImageUploadResponse> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('productName', product);

    return this.http
      .post<ImageUploadResponse>(
        `${this.serverApi}/DocumentationImageUpload`,
        formData
      )
      .pipe(
        map((response: ImageUploadResponse) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => throwError(error))
      );
  }

  private createHomePage(): void {
    if (!this.rootPage.pages) {
      this.rootPage.pages = new Array<DocumentationPage>();
    }

    const homePage = new DocumentationPage();
    homePage.pages = new Array<DocumentationPage>();
    homePage.widgets = new Array<BaseWidget>();

    this.rootPage.pages.push(homePage);

    this.rootPage.pages[0].title = 'Home';
    this.rootPage.pages[0].link[0] = 'home';
    this.rootPage.pages[0].leadHeadline = 'Home Lead Headline';
  }

  /**
   *
   * Test data will be removed
   *
   */

  public getMatIcons(): string[] {
    return cardIcons.icons;
  }

  public initData(): void {
    this.createHomePage();

    const childPage2 = new DocumentationPage();
    childPage2.title = r({ min: 1, max: 2, join: ' ' });
    childPage2.link.push(childPage2.title.toLowerCase().split(' ').join('-'));
    this.buildNestedPageList(childPage2, 2);
    this.rootPage.pages.push(childPage2);
    this.rootPage.pages[1].title = 'try this';
    this.rootPage.pages[1].link = ['try-this'];
    this.rootPage.pages[1].pages[0].title = 'further';
    this.rootPage.pages[1].pages[0].link = [
      ...this.rootPage.pages[1].link,
      'further',
    ];
    this.rootPage.pages[1].pages[0].leadHeadline = 'Further headline';
    this.rootPage.pages[1].pages[0].pages[0].title = 'deepest';
    this.rootPage.pages[1].pages[0].pages[0].link = [
      ...this.rootPage.pages[1].pages[0].link,
      'deepest',
    ];
    this.rootPage.pages[1].pages[0].pages[0].leadHeadline = 'Deepest headline';
    this.rootPage.pages[1].pages[0].pages[1].title = 'deepest neighbour';
    this.rootPage.pages[1].pages[0].pages[1].link = [
      ...this.rootPage.pages[1].pages[0].link,
      'deepest-neighbour',
    ];
    this.rootPage.pages[1].pages[0].pages[1].leadHeadline =
      'Deepest neighbour headline';

    const childPage3 = new DocumentationPage();
    childPage3.title = r({ min: 1, max: 2, join: ' ' });
    childPage3.link.push(childPage3.title.toLowerCase().split(' ').join('-'));
    this.buildNestedPageList(childPage3, 3);
    this.rootPage.pages.push(childPage3);

    for (let i = 0; i < 4; i++) {
      const child = new DocumentationPage();
      child.title = r({ min: 1, max: 2, join: ' ' });
      child.link.push(child.title.toLowerCase().split(' ').join('-'));
      this.buildNestedPageList(child, 1);
      this.rootPage.pages.push(child);
    }
  }

  private buildNestedPageList(page: DocumentationPage, levels: number): void {
    if (levels === 0) {
      return;
    }

    page.pages = new Array<DocumentationPage>();

    for (let i = 0; i < 2; i++) {
      const childPage = new DocumentationPage();
      childPage.title = r({ min: 1, max: 2, join: ' ' });
      childPage.link.push(...page.link);
      childPage.link.push(childPage.title.toLowerCase().split(' ').join('-'));

      DocumentationDataService.addWidgets(childPage);

      this.buildNestedPageList(childPage, levels - 1);

      page.pages.push(childPage);
    }
  }

  private static addWidgets(page: DocumentationPage): void {
    page.pages = new Array<DocumentationPage>();
    page.leadHeadline =
      page.title.charAt(0).toUpperCase() +
      page.title.substring(1) +
      ' Lead Headline';
    page.widgets = new Array<BaseWidget>();
    page.widgets.push(this.buildWidget(WidgetTypes.TEXT));
    page.widgets.push(this.buildWidget(WidgetTypes.HEADLINE));
    page.widgets.push(this.buildWidget(WidgetTypes.TEXT));
    page.widgets.push(this.buildWidget(WidgetTypes.CARD));
    page.widgets.push(this.buildWidget(WidgetTypes.HEADLINE));
    page.widgets.push(this.buildWidget(WidgetTypes.IMAGE));
    page.widgets.push(this.buildWidget(WidgetTypes.TEXT));
    page.widgets.push(this.buildWidget(WidgetTypes.TEXT));
    page.widgets.push(this.buildWidget(WidgetTypes.TEXT));
    page.widgets.push(this.buildWidget(WidgetTypes.HEADLINE));
    page.widgets.push(this.buildWidget(WidgetTypes.TEXT));
    page.widgets.push(this.buildWidget(WidgetTypes.TEXT));
    page.widgets.push(this.buildWidget(WidgetTypes.TEXT));
    page.widgets.push(this.buildWidget(WidgetTypes.CARD));
  }

  private static buildWidget(type: string): BaseWidget {
    let widget;
    switch (type) {
      case WidgetTypes.TEXT: {
        widget = new Text();
        widget.html = r({ min: 15, max: 100, join: ' ' });
        break;
      }
      case WidgetTypes.IMAGE: {
        widget = new Image();
        widget.html = 'https://picsum.photos/200/300';
        break;
      }
      case WidgetTypes.HEADLINE: {
        widget = new Headline();
        const words = r({ min: 2, max: 4, join: ' ' }).split(' ');
        for (let i = 0; i < words.length; i++) {
          const first = words[i].charAt(0).toUpperCase();
          words[i] = first + words[i].substr(1);
        }
        widget.html = words.join(' ');
        widget.title = widget.html;
        break;
      }
      case WidgetTypes.CARD: {
        widget = new Card();
        widget.textWidget.html =
          r({ min: 15, max: 45, join: ' ' }) +
          '<br><br>' +
          r({ min: 15, max: 45, join: ' ' }) +
          '<br><br>' +
          r({
            min: 15,
            max: 45,
            join: ' ',
          });
        widget.textWidget.text = widget.textWidget.html;
        widget.html = widget.text;
        break;
      }
      case WidgetTypes.VIDEO: {
        widget = new Video();
        widget.url = '';
        break;
      }
    }
    widget.text = widget.html;
    return widget;
  }
}
