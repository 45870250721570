import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent implements OnChanges {

    @Input() promise: any;

    public fade: boolean = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.promise) { /* for fade animation */
            setTimeout(() => {
                this.fade = true;
                this.changeDetectorRef.detectChanges();
            });
        }
    }
}
