import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from "./components/home/home.component";
import { TessProductComponent } from "./components/tess-product/tess-product.component";
import { AcademyProductComponent } from "./components/academy-product/academy-product.component";
import { AboutComponent } from "./components/about/about.component";
import { BlogDetailsComponent } from "./components/blog-details/blog-details.component";
import { PricingComponent } from "./components/pricing/pricing.component";
import { ContactComponent } from "./components/contact/contact.component";
import { BlogComponent } from "./components/blog/blog.component";
import { ServiceDetailComponent } from "./components/service-detail/service-detail.component";
import { PreloadConfigResolver } from './preload-config.resolver';

const routes: Routes = [
    {
        path: '',
        resolve: { data: PreloadConfigResolver },
        children: [
            {
                path: 'home',
                component: HomeComponent
            },
            {
                path: 'product/tess',
                component: TessProductComponent
            },
            {
                path: 'product/academy',
                component: AcademyProductComponent
            },
            {
                path: 'about',
                component: AboutComponent
            },
            {
                path: 'blog-details',
                component: BlogDetailsComponent
            },
            {
                path: 'blog',
                component: BlogComponent
            },
            {
                path: 'contact',
                component: ContactComponent
            },
            {
                path: 'pricing',
                component: PricingComponent
            },
            {
                path: 'service-detail',
                component: ServiceDetailComponent
            },
            {
                path: 'product/:id/documentation',
                loadChildren: './components/documentation/documentation.module#DocumentationModule'
            },
            {
                path: '**',
                redirectTo: 'home'
            },
        ],
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
