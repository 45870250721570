import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NavbarCommunicationService {

    public isScrollingDown = -1;
    public ignoreScrolling: boolean = false;
    public isAnchorScrolling: boolean = false;

    private prevScroll = 0;
    private scrollTimer: number = -1;

    private searchNavigation: ReplaySubject<void> = new ReplaySubject<void>();
    private toggleDocumentationSideMenu: ReplaySubject<boolean> = new ReplaySubject<boolean>();

    public onWindowScroll(): void {
        if (this.isAnchorScrolling) {
            this.prevScroll = window.pageYOffset;
            return;
        }

        if (this.ignoreScrolling) {
            /**
             * when ignoring scrolling, a timeout is created on scroll event
             * if another scroll event occurs, reset the timer.
             * When the timer finally does fire, then you can assume the scrolling has stopped and ignoring is disabled.
             */
            this.prevScroll = window.pageYOffset;
            if (this.scrollTimer != -1) {
                clearTimeout(this.scrollTimer);
            }
            this.scrollTimer = setTimeout(() => this.ignoreScrolling = false, 100);
            return;
        }
        const currentScrollPos = window.pageYOffset;
        if (currentScrollPos == 0) {
            this.isScrollingDown = -1;
        } else if (currentScrollPos > this.prevScroll && currentScrollPos > 70) {
            this.isScrollingDown = 1;
        } else if (currentScrollPos < this.prevScroll) {
            this.isScrollingDown = 0;
        }
        this.prevScroll = currentScrollPos;
    }

    public navigateFromSearch(): void {
        this.searchNavigation.next();
    }

    public listenToSearchNavigation(): Observable<void> {
        return this.searchNavigation.asObservable();
    }

    /* Tablet-Phone below */
    public toggleDocumentationMenu(show: boolean): void {
        this.toggleDocumentationSideMenu.next(show);
    }

    public listenToDocumentationMenuToggling(): Observable<boolean> {
        return this.toggleDocumentationSideMenu.asObservable();
    }
}