import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { get as lGet } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: any;

  constructor(private http: HttpClient) { }

  public loadConfig(): Observable<any> {
    if (this.config) return of(this.config);

    return this.http.get<any>('/configuration.json')
      .pipe(
        tap(data => this.config = data)
      );
  }

  public get(key: string): any {
    const result = lGet(this.config, key);
    if (!result) {
      return false;
    }

    return result;
  }
}
